import styled from "@emotion/styled";
import { IonContent } from "@ionic/react";
import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  ANDROID_APP_DOWNLOAD_LINK,
  IOS_APP_DOWNLOAD_LINK
} from "src/constants/misc";
import Path from "src/constants/path";
import translate from "src/lib/translate";
import Footer from "src/ui/components/Footer/Footer";
import GetHybridAppStepContent from "src/ui/components/GetHybridAppStepContent/GetHybridAppStepContent";
import Translate from "src/ui/components/Translate/Translate";

const Title = styled.div`
  @media screen and (min-width: 960px) {
    padding-left: 7rem;
  }
`;

const AppDownloadPage: FC = () => {
  const [contentVisible, setContentVisible] = useState(false);
  const navigate = useNavigate();

  const navigateToApp = (): void => {
    navigate(Path.app);
  };

  const getPlatform = (): "android" | "ios" | "other" => {
    const ua = navigator.userAgent.toLowerCase();
    if (/android/.test(ua)) {
      return "android";
    }
    if (/applewebkit/.test(ua)) {
      return "ios";
    }
    return "other";
  };

  useEffect(() => {
    if (getPlatform() === "android") {
      window.location.replace(ANDROID_APP_DOWNLOAD_LINK);
    } else if (getPlatform() === "ios") {
      window.location.replace(IOS_APP_DOWNLOAD_LINK);
    }

    const timeout = setTimeout(() => {
      setContentVisible(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const appDownloadImgLink =
    "https://cdn.sanity.io/images/g38rxyoc/production/fc651383ca2ba520d8352825cb70bf3035a1c571-1122x1120.png";

  return (
    <IonContent scrollEvents>
      {contentVisible && (
        <div>
          <nine-navigation only-logo>
            <div slot="actions">
              <Link to="/">
                <Translate msg="signin" />
              </Link>
            </div>
          </nine-navigation>

          <main>
            <nine-hero
              keep-img-ratio
              image={appDownloadImgLink}
              variant="split"
              textAlign="left"
              style={{ maxWidth: "1200px", margin: "0 auto" }}
            >
              <Title slot="title">
                <nine-spacer s="md"></nine-spacer>
                <div className="as-eyebrow">
                  <Translate msg="downloadApp.about" />
                </div>
                <nine-spacer s="xxs"></nine-spacer>
                <h3 className="as-h3">
                  <Translate msg="downloadApp.deviceNotSupported.title" />
                </h3>
                <p className="as-body">
                  <Translate msg="downloadApp.deviceNotSupported.description" />
                </p>
                <ul>
                  <li>
                    <Translate msg="downloadApp.deviceNotSupported.ios" />
                  </li>
                  <li>
                    <Translate msg="downloadApp.deviceNotSupported.android" />
                  </li>
                </ul>
                <nine-spacer s="xxs"></nine-spacer>
                <GetHybridAppStepContent
                  darkCreamBg
                  forceQRCode
                  content={translate("downloadApp.scanQR")}
                />
              </Title>
            </nine-hero>
          </main>

          <Footer
            title={translate("9am.slogan")}
            button={{
              label: translate("login.alternative.browser"),
              onClick: navigateToApp
            }}
          />
        </div>
      )}
    </IonContent>
  );
};

export default AppDownloadPage;
